import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import VueSession from 'vue-session'
import VueImg from 'v-img';

import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';
import ProgressBar from 'vuejs-progress-bar';
import SweetModal from 'sweet-modal-vue/src/plugin.js'
import Viewer from 'v-viewer';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(ProgressBar)
Vue.filter('numFormat', numFormat(numeral));

Vue.use(Viewer, {
    defaultOptions: {
        "navbar": false,
        "title": false
    }
})
Vue.use(SweetModal)
Vue.use(VueImg);
Vue.use(VueRouter)
Vue.use(VueSession)
Vue.use(require('vue-moment'));

import Login from './components/Login.vue'
import Home from './components/Home.vue'
// import Create from './components/Create_Transaction.vue'
import Create from './components/Create_Transaction.vue'
import View from './components/View_Transkeep.vue'
import ViewMenu from './components/View_Menu.vue'
import CheckList from './components/Check_List.vue'
import Edit from './components/Edit_Transkeep.vue'
import 'viewerjs/dist/viewer.css'

// Vue.component('sweet-button', SweetModal);

const router = new VueRouter({
    // mode: "history",
    base: '/transkeep',
    routes: [
        { path: '/', name: 'Login', component: Login, props: true },
        { path: '/home', name: 'Home', component: Home, props: true },
        { path: '/create', name: 'Create', component: Create, props: true },
        { path: '/view', name: 'View', component: View, props: true },
        { path: '/checklist/edit', name: 'Edit', component: Edit, props: true },
        { path: '/view/menu', name: 'ViewMenu', component: ViewMenu, props: true },
        { path: '/checklist', name: 'CheckList', component: CheckList, props: true },
    ]
})
Vue.config.productionTip = false
new Vue({
    render: h => h(App),
    router,
}).$mount('#app')