<template>
  <div class="container">
    <div class="nav">
      <div class="item1">
        <ul>
          <li style="font-weight: bold">Hi. <small style="font-weight: 100;">{{login}}</small> </li>
        </ul>
      </div>
      <div class="item2">
      <ul>
        <li style="font-weight: bold" v-if="login" v-on:click="logout" class="btnLogout"><small>LOGOUT</small></li>
      </ul>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data:function(){
    return{
      login: false,
    }
  },
  mounted(){
    this.login = this.$session.get('session_username')
  },
  methods:{
    logout(){
      this.$session.clear()
      location.reload();
      // this.$router.push({ name: "Login" });
    }
  }
};
</script>

<style lang="scss">
.container {
  margin: 0 auto;
  position: relative;
}

.nav {
  position: sticky;
  top: 0;
  z-index: 99;
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* padding: 0 100px; */
  align-items: center;
  background-image: linear-gradient(to right top, #ccd4da, #d4dce0, #dce3e6, #e5ebec, #eef2f3);
  color: rgb(0, 136, 148);
  // border-bottom: 1.5px solid #c5c5c5;
}

.nav ul {
  padding: 0;
  margin: 0;
  display: flex;
}

.nav ul li {
  list-style: none;
  padding: 0 15px;
  line-height: 50px;
  // text-transform: uppercase;
  height: 50px;
  cursor: pointer;
  color:#000;
  // font-weight: bold;
}

// .nav ul li:hover {
//     color: red;
//     cursor: pointer;
//     transition: .5s;
// }
</style>
