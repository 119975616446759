<template>
  <div class="container_view_transaction">
    <div class="header_button" style="width: 100%">
      <div v-if="$session.get('from_page') == 'check_list'">
        <router-link :to="{ name: 'CheckList' }" tag="p">
          <Zondicon icon="cheveron-left" style="width: 30px; margin: 10px 0 0 10px" />
        </router-link>
      </div>
      <div class="font-text">Status : {{ actions }}</div>
    </div>
    <div class="detail">
      <div class="item">
        <div class="topic">พนักงาน</div>
        <div class="content">{{result_get_transaction.user_name}}</div>
      </div>
      <!--  -->
      <div class="hr"></div>
      <div class="hr"></div>
      <div class="item">
        <div class="topic">วันที่</div>
        <div
          class="content"
        >{{result_get_transaction.record_timestamp | moment("YYYY-MM-DD HH:mm") }}</div>
      </div>
      <!--  -->
      <div class="hr"></div>
      <div class="hr"></div>
      <!--  -->
      <div class="item">
        <div class="topic">ประเภท</div>
        <div class="content">{{result_get_transaction.category}}</div>
      </div>
      <!--  -->
      <div class="hr"></div>
      <div class="hr"></div>
      <!--  -->
      <div class="item">
        <div class="topic">เจ้าหน้าที</div>
        <div class="content">{{result_get_transaction.division}}</div>
      </div>
      <div class="hr"></div>
      <div class="hr"></div>
      <div class="item">
        <div class="topic">จำนวนเงิน</div>
        <div class="content">{{Number(result_get_transaction.amount).toLocaleString()}}</div>
      </div>
      <div class="hr"></div>
      <div class="hr"></div>
      <div class="item">
        <div class="topic">บันทึกเพิ่มเติม</div>
        <div class="content">{{result_get_transaction.note}}</div>
      </div>
      <div class="hr"></div>
      <div class="hr"></div>
      <div class="item">
        <div class="topic">สถานะ</div>
        <div class="content">{{result_get_transaction.approve_status}}</div>
      </div>
      <div class="hr"></div>
      <div class="hr"></div>
      <div class="item">
        <div class="topic">เหตุผลการปฏิเสธ</div>
        <div
          class="content"
          v-if="result_get_transaction.reject_note != null"
        >{{result_get_transaction.reject_note}}</div>
        <div class="content" v-else>-</div>
      </div>
      <div class="hr"></div>
      <div class="hr"></div>
      <!-- <div class="item"> -->
      <div class="section_edit_button">
        <button class="btn_approve" style="margin: 10px;" @click="updateAppoveStatus('Approve')">
          <a style="font-size:18px;">Approve</a>
        </button>
        <button class="btn_reject" style="margin: 10px;" @click="open">
          <a style="font-size:18px;">Reject</a>
        </button>
        <!-- </div> -->
      </div>
    </div>

    <div class="preview_view">
      <viewer v-bind:key="item.id" v-for="(item , index ) in result_get_image_transaction">
        <img
          :src="result_get_image_transaction[index].image_url"
          :key="result_get_image_transaction[index].id"
          style="height:160px;"
        />
      </viewer>
    </div>

    <sweet-modal icon="warning" ref="modal" title="Reject">
      <div>
        <textarea v-model="rejectNote" class="textArea" placeholder=" เหตุผลการปฏิเสธ"></textarea>
      </div>
      <div>
        <button
          class="confirmButton"
          style="background-color: #4CAF50; margin: 10px"
          @click="updateAppoveStatus('Reject')"
        >ยืนยัน</button>
        <button
          class="confirmButton"
          style="background-color: #f44336; margin: 10px"
          @click="close"
        >ปิด</button>
      </div>
    </sweet-modal>
  </div>
</template>

<script>
const axios = require("axios");
const moment = require("moment");
// import { Carousel, Slide } from "vue-carousel";
import Zondicon from "vue-zondicons";
export default {
  components: {
    // Carousel,
    // Slide,
    Zondicon
  },
  data: function() {
    return {
      // path_url
      // path: "http://127.0.0.1:3000",
      path: "https://transkeep-engine.945.report",
      actions: "",
      result_get_transaction: [],
      result_get_image_transaction: [],
      rejectNote: ""
    };
  },
  mounted() {
    if (!this.$session.get("session_username")) {
      this.$router.push({ name: "Login" });
    }
    if (this.$session.get("session_username_role") == 0) {
      this.$router.push({ name: "Home" });
    }
    this.getDataTransaction();
    this.getImageTransaction();
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    getDataTransaction() {
      var data = {
        transaction_id: this.$session.get("session_transaction_id")
      };
      axios
        .post(this.path + "/service/get-data-transaction-id", data)
        .then(response => {
          this.result_get_transaction = response.data.items[0];
          this.actions = this.result_get_transaction.approve_status;
        });
    },
    getImageTransaction() {
      var data = {
        transaction_id: this.$session.get("session_transaction_id")
      };

      axios
        .post(this.path + "/service/get-img-transaction-id", data)
        .then(response => {
          this.result_get_image_transaction = response.data.items;
        });
    },
    updateAppoveStatus(status) {
      var data = {
        userId: this.$session.get("session_username_id"),
        transactionId: this.$session.get("session_transaction_id"),
        approveStatus: status,
        rejectNote: this.rejectNote,
        statusDate: moment().format("YYYY-MM-DD HH:mm:ss")
      };
      const vm = this;
      if (status == "Reject") {
        if (this.rejectNote.length == 0) {
          alert("กรุณาระบุหมายเหตุตีกลับ");
        } else {
          axios
            .post(this.path + "/service/updateTransaction", data)
            .then(response => {
              console.log(response);
              alert("ทำการเปลี่ยนสถานะสำเร็จ");
              vm.$router.push({ name: "CheckList" });
            });
        }
      } else {
        axios
          .post(this.path + "/service/updateTransaction", data)
          .then(response => {
            console.log(response);
            alert("ทำการเปลี่ยนสถานะสำเร็จ");
            vm.$router.push({ name: "CheckList" });
          });
      }
    }
  }
};
</script>

<style lang="scss">
.container_view_transaction {
  color: #555555;

  .header_button {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .section_edit_button {
    text-align: right;
    margin: 30px auto;
    .btn_approve {
      color: #fff;
      font-weight: bold;
      padding: 10px 30px;
      // background: #ffffff;
      border: none;
      border-radius: 5px;
      -webkit-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      -moz-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      background-image: linear-gradient(
        to right top,
        #56ab2f,
        #6bb83c,
        #80c549,
        #94d356,
        #a8e063
      );
    }
    .btn_reject {
      color: #fff;
      font-weight: bold;
      padding: 10px 30px;
      // background: #ffffff;
      border: none;
      border-radius: 5px;
      -webkit-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      -moz-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      background-image: linear-gradient(
        to right top,
        #e64949,
        #e64949,
        #e64949,
        #e64949,
        #e64949
      );
    }
  }

  .detail {
    margin-top: 5px;
    background-color: #ffffff;
    padding: 20px;
    -webkit-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
    -moz-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
    box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);

    .hr {
      height: 0.5px;
      background-color: #d3d3d3;
    }
    .item {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin: 10px auto;
      .topic {
        // font-weight: bold;
      }
      .content {
        text-align: right;
      }
    }
  }

  .preview_view {
    background-color: #ffffff;
    margin: 50px auto;
    text-align: center;
    padding: 40px 0;
    -webkit-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
    -moz-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
    box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
  }
  .responsive {
    width: 100%;
    height: auto;
    padding-right: 30px;
    padding-left: 30px;
  }

  .font-text {
    padding: 15px;
    text-align: right;
  }

  .section_button {
    text-align: center;
    margin: 50px auto;
    button {
      color: #fff;
      font-weight: bold;
      padding: 10px 30px;
      // background: #ffffff;
      border: none;
      border-radius: 5px;
      -webkit-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      -moz-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      background-image: linear-gradient(
        to right top,
        #56ab2f,
        #6bb83c,
        #80c549,
        #94d356,
        #a8e063
      );
    }
  }

  .section_Back {
    text-align: center;
    margin: 50px auto;
    button {
      color: #fff;
      font-weight: bold;
      padding: 10px 30px;
      // background: #ffffff;
      border: none;
      border-radius: 5px;
      -webkit-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      -moz-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      background-image: linear-gradient(
        to right top,
        #e53935,
        #e5433f,
        #e54c49,
        #e45552,
        #e35d5b
      );
    }
  }
  .textArea {
    float: left;
    resize: none;
    width: 100%;
    min-height: 75px;
    outline: none;
    resize: none;
    border: 1px solid grey;
  }
  .confirmButton {
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }
}
</style>