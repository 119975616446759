
<template>
  <div id="app">
    <div class="nav_barr">
      <navbar v-if="this.$session.get('session_username')"></navbar>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
export default {
  name: 'app',
    components: {
    Navbar
  }

}
</script>

<style lang="scss">
.nav_barr{
  top: 0;
  position: sticky;
  z-index: 10;
}
.lds-facebook {
    position: absolute;
    top: 30%;
    left: 38.5%;
    transform: translate(-50%, -50%);
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: rgb(0, 136, 148);;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}

</style>

