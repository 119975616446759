<template>
  <div class="view_menu">
    <div class="header_button" style="width: 10%">
      <router-link :to="{ name: 'Home' }" tag="p">
        <Zondicon icon="cheveron-left" style="width: 30px; margin: 10px 0 0 10px" />
      </router-link>
    </div>

    <div class="row" style="text-align: center; font-size: 16px;">
          <input class="form-control" type="text" v-model="searchQuery" v-on:keyup="getDataViewMenu()" placeholder="Search" style="margin-bottom: 15px;width: 60%;"/>
    </div>
    <div class="content">
      <div
        v-for="(item , index) in result_get_data"
        v-bind:key="item.id"
        class="item"
        v-on:click="detail(item)"
      >
        <div class="left">
          <small>ประเภท : {{result_get_data[index].category}}</small>
          <br />
          <small>หน่วยงาน : {{result_get_data[index].division}}</small>
          <br />
          <small>บันทึกเพิ่มเติม : {{result_get_data[index].note}}</small>
          <br />
          <small>สถานะ : {{result_get_data[index].approve_status}}</small>
          <br />
          <small v-if="result_get_data[index].reject_note != null">เหตุผลการปฏิเสธ : {{result_get_data[index].reject_note}}</small>
          <small v-else>เหตุผลการปฏิเสธ : -</small>
          <br />
          <small
            v-if="$session.get('session_username_id') == 1"
          >โดย : {{result_get_data[index].user_name}}</small>
        </div>
        <div class="right">
          <label
            style="font-size: 28px ; font-weight: bold ; "
          >{{result_get_data[index].amount.toLocaleString()}}</label>
          <br />
          <small>{{result_get_data[index].record_timestamp | moment("YYYY-MM-DD")}}</small>
        </div>
      </div>
    </div>
      <pagination v-model="page" :records="total" :per-page="50" @paginate="myCallback"/>
  </div>
</template>

<script>
const axios = require("axios");
import Zondicon from "vue-zondicons";
import Pagination from 'vue-pagination-2';

export default {
  components: {
    Zondicon,
    Pagination
  },
  data: function() {
    return {
      // path_url
      // path: "http://127.0.0.1:3000",
      path: "https://transkeep-engine.945.report",
      result_get_data: [],
      count:0,
      page:1,
      total:0,
      searchQuery: "",
    };
  },
  mounted() {
    if (!this.$session.get("session_username")) {
      this.$router.push({ name: "Login" });
    }
    this.getDataViewMenu(1);
  },
  methods: {
    getDataViewMenu(page) {
      if (this.$session.get("session_username_role") == 1) {
        axios
          .get(this.path + "/service/get-data-view-menu-admin?page=" + page +"&search=" + this.searchQuery)
          .then(response => {
            this.result_get_data = response.data.items;
            this.count = response.data.items.length;
            this.total = response.data.total;
          });
      } else {
        var data = {
          user_id: this.$session.get("session_username_id"),
          search: this.searchQuery,
          page: page
        };
        axios
          .post(this.path + "/service/get-data-view-menu", data)
          .then(response => {
            this.result_get_data = response.data.items;
            this.total = response.data.total;
          });
      }
    },
    detail(e) {
      this.$session.set("session_transaction_id", e.transaction_id);
      this.$session.set("from_page", "view_menu");
      this.$router.push({ name: "View" });
    },
    myCallback(page){
      this.getDataViewMenu(page);
    }
  }
};
</script>

<style lang="scss">
.view_menu {
  .content {
    display: grid;
    grid-template-columns: 1fr;

    .item:nth-child(even) {
      color: #363636;
      display: grid;
      grid-template-columns: 1fr 1fr;
      // background-image: linear-gradient(to right top, #0f2027, #12252c, #152931, #192e37, #1c333c, #1e3741, #213c46, #23404b, #254551, #274957, #2a4e5e, #2c5364);
      // background-image: linear-gradient(to right top, #8e9eab, #a4b3bc, #bcc8ce, #d4dde0, #eef2f3);
      background-image: linear-gradient(
        to right top,
        #ece9e6,
        #f2eeed,
        #f6f4f4,
        #faf9fa,
        #ffffff
      );
      margin: 10px;
      padding: 10px 15px;
      border: none;
      border-radius: 10px;
      -webkit-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      -moz-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      .left {
      }

      .right {
        text-align: right;
        // font-weight: bold;
      }
    }
    .item:nth-child(odd) {
      color: #fff;
      display: grid;
      grid-template-columns: 1fr 1fr;
      // background-image: linear-gradient(to right top, #0f0c29, #131433, #19193e, #1f1e49, #262454, #242b5b, #223162, #203868, #184069, #184768, #204d66, #2c5364);
      // background-image: linear-gradient(to right top, #1d976c, #40af7f, #5cc792, #78e0a5, #93f9b9);
      // background-image: linear-gradient(to right top, #1d976c, #39a275, #4dad7d, #60b987, #72c490);
      background-image: linear-gradient(
        to right top,
        #19815d,
        #298e64,
        #389c6b,
        #46a972,
        #55b779
      );
      margin: 10px;
      padding: 10px 15px;
      border: none;
      border-radius: 10px;
      -webkit-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      -moz-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      .left {
      }

      .right {
        text-align: right;
        // font-weight: bold;
        // text-shadow: 1px 1px .5px #000;
      }
    }
  }

  .section_Back {
    text-align: center;
    margin: 50px auto;

    button {
      color: #fff;
      font-weight: bold;
      padding: 10px 30px;
      // background: #ffffff;
      border: none;
      border-radius: 5px;
      -webkit-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      -moz-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      background-image: linear-gradient(
        to right top,
        #e53935,
        #e5433f,
        #e54c49,
        #e45552,
        #e35d5b
      );
    }
  }
}
</style>