<template>
  <div class="home_trankeep">
      <div class="content">
          <br>
      
          <router-link :to="{ name: 'Create' }" tag="p">
            <div class="item" style="background-image: linear-gradient(to right top, #56ab2f, #6bb83c, #80c549, #94d356, #a8e063);">
              <div class="left">
                <Zondicon icon="add-outline" style="width: 30px; margin: 10px 0 0 10px ; fill: #fff " />
              </div>
              <div class="right">เพิ่มรายการใหม่</div>
            </div>
          </router-link>
          <br>
    
          <router-link :to="{ name: 'ViewMenu' }" tag="p">
            <div class="item" style="background-image: linear-gradient(to right top, #f2994a, #f4a549, #f4b148, #f4bd49, #f2c94c);">
                <div class="left">
                  <Zondicon icon="reload" style="width: 30px; margin: 10px 0 0 10px ; fill: #fff" />
                </div>
                <div class="right">ดูรายการย้อนหลัง</div>
            </div>
          </router-link>
          <br>
        
          <router-link :to="{ name: 'CheckList' }" tag="p" v-if="role == 1">
            <div class="item" style="background-image: linear-gradient(to right top, #f297f2, #ef84ef, #eb6feb, #e758e7, #e33be3);">
                <div class="left">
                  <Zondicon icon="checkmark-outline" style="width: 30px; margin: 10px 0 0 10px ; fill: #fff" />
                </div>
                <div class="right">ตรวจสอบรายการ</div>
            </div>
          </router-link>
      </div>
  </div>
</template>

<script>
import Zondicon from 'vue-zondicons'
export default {
    components: {
      Zondicon
    },
    data: () => {
      return {
        role: 0
      }
    },
    mounted(){
        if (!this.$session.get("session_username")) {
            this.$router.push({ name: "Login" });
        }
        this.role = this.$session.get("session_username_role");
    }
}
</script>

<style lang="scss">
@font-face {
  font-family: "Prompt";
  src: url("../assets/fonts/Prompt-Light.ttf");
}
*{
  font-family: 'Prompt';
  outline: none;
}
.home_trankeep{
    .content{
        display: grid;
        grid-template-columns: 1fr;

        .item{
            display: grid;
            grid-template-columns: 1fr;
            color: #ffffff;
            margin: 10px 30px;
            padding: 50px 15px;
            border-radius: 10px;
            -webkit-box-shadow: 0px 6px 5px -2px rgba(0,0,0,0.33);
            -moz-box-shadow: 0px 6px 5px -2px rgba(0,0,0,0.33);
            box-shadow: 0px 6px 5px -2px rgba(0,0,0,0.33);
            text-align: center;
            font-size: 22px;
        }
    }
}
</style>