<template>
  <div class="login">
    <div class="content">
      <h3>Transkeep</h3>
      <input placeholder="Username" v-model="username" v-on:keyup.enter="btnLogin" />
      <br />
      <input placeholder="Password" type="password" v-on:keyup.enter="btnLogin" v-model="password" />
      <br />
      <br />
      <button v-on:click="btnLogin">LOGIN</button>
    </div>
  </div>
</template>

<script>
const axios = require("axios");

export default {
  title: 'Transkeep',
  data: function() {
    return {
      // path_url
      // path: "http://127.0.0.1:3000",
      path: "https://transkeep-engine.945.report",
      username: "",
      password: ""
    };
  },
  mounted() {
    if (this.$session.get("session_username")) {
      this.$router.push({ name: "Home" });
    }
  },
  methods: {
    btnLogin() {
      var data = {
        username: this.username,
        password: this.password
      };
      axios
        .post(this.path + "/service/login", data, {
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then(response => {
          if (response.data.items[0]) {
            this.$session.start();
            this.$session.set(
              "session_username",
              response.data.items[0].username
            );
            this.$session.set(
              "session_username_role",
              response.data.items[0].role
            );
            this.$session.set("session_username_id", response.data.items[0].id);
            this.$session.set(
              "session_username_division",
              response.data.items[0].division
            );
            this.$router.push({ name: "Home" });
            // location.reload();
          } else {
            alert("Username หรือ Password ไม่ถูกต้อง ");
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style lang="scss">
.login {
  width: 500px;
  margin: 250px auto;

  .content {
    text-align: center;
    // border: 2px solid rgb(0, 136, 148);;
    padding: 30px;
    // background-color: #fff;
    color: #fff;

    // border-radius: 10px;
    // box-shadow:0 4px 10px 4px rgba(#13232f,.3);

    h3 {
      margin: 50px auto;
      text-transform: uppercase;
      color: rgb(0, 136, 148);
      letter-spacing: 2px;
    }

    input {
      border: none;
      border-bottom: 2px solid rgb(0, 136, 148);
      background: none;
      outline: none;
      padding: 5px 10px;
      margin: 10px auto;
      width: 100%;
      text-align: center;
      transition: 0.5s;
      color: rgb(0, 136, 148);
      &::placeholder {
        color: rgb(0, 136, 148);
      }
      &:focus {
        border-bottom: 2px solid rgb(0, 136, 148);
      }
    }

    button {
      color: #fff;
      font-weight: bold;
      padding: 10px 30px;
      width: 80%;
      // background: #ffffff;
      border: none;
      border-radius: 5px;
      -webkit-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      -moz-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      background-image: linear-gradient(
        to right top,
        #56ab2f,
        #6bb83c,
        #80c549,
        #94d356,
        #a8e063
      );
    }
  }
}

@media screen and (min-width: 0px) and (max-width: 800px) {
  .login {
    width: 350px;
    margin: 100px auto;
  }
}
</style>