<template>
  <div class="page_home_2">
    <div class="header_button" style="width: 10%">
      <router-link :to="{ name: 'Home' }" tag="p">
        <Zondicon icon="cheveron-left" style="width: 30px; margin: 10px 0 0 10px" />
      </router-link>
    </div>
    <div v-if="loading" class="lds-facebook">
      <div></div>
      <div></div>
      <div></div>
    </div>

    <progress-bar v-if="loading" class="progress_bar" :options="options" :value="loading_percent" />

    <div v-if="!loading" class="container">
      <div v-if="division != null">
        <div class="section_camera">
          <div>
            <label>
              เริ่มบันทึก
              <input
                type="file"
                id="files"
                ref="files"
                accept="image/*"
                v-on:change="handleFilesUpload"
              />
            </label>
          </div>

          <div class="preview">
            <carousel :per-page="1" :paginationActiveColor="'#008894'" :paginationColor="'#969696'">
              <slide v-bind:key="item.id" v-for="(item , index ) in selectedFile" class="picture">
                <embed v-img :src="selectedFile[index]" width="100vw" />
                <br />
                <button class="btnRemoveFile" v-on:click="removeFile(index)">Delete</button>
              </slide>
            </carousel>
          </div>
        </div>
        <br />
        <div v-if="show_category" class="section_amount">
          <input v-model="divisionName" disabled />
        </div>
        <br v-if="show_category" />
        <div v-if="show_category" class="section_category">
          <select v-model="dropdown_category" v-on:change="selectedCategory($event)">
            <option selected disabled>Category</option>
            <option
              v-for="(item , index) in result_get_category"
              v-bind:key="item.id"
              :value="JSON.stringify(item)"
            >{{result_get_category[index].name_th}}</option>
          </select>
        </div>
        <br v-if="show_category" />

        <div v-if="show_amout" class="section_amount">
          <vue-numeric
            class="amout"
            separator=","
            v-model="input_amount"
            placeholder="Amount (บาท)"
            type="tel"
            v-bind:precision="2"
            inputmode="decimal"
          ></vue-numeric>
        </div>
        <br />
        <div v-if="show_note" class="section_amount">
          <input v-model="input_note" placeholder="Note" />
        </div>

        <br />
        <div v-if="show_submit" class="section_submit">
          <button v-on:click="submit">Submit</button>
        </div>
      </div>
      <div class="section_division" v-else> 
       <h1>เลือกบริษัท</h1>
        <div class="detail">
          <div   v-for="(item , index) in divisionData" v-bind:key="item.id">
            <div class="sub-detail">
     
           <div class="sub-box">
            <label style="font-size:20px;" for="one"> {{ divisionData[index].division }}</label>
            </div>
                   <div class="sub-boxchk">
            <input type="radio" :value="divisionData[index].record_id" v-model="picked" style="width: 25px;height: 25px;" />
           </div>
           
            </div>
            <br />
          </div>
        </div>
        <div class="box-btn" >
         <button  v-on:click="selectDivision" :disabled="picked == null">ถัดไป</button>
         </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require("axios");
import VueNumeric from "vue-numeric";
import { Carousel, Slide } from "vue-carousel";
import Zondicon from "vue-zondicons";
export default {
  components: {
    VueNumeric,
    Carousel,
    Slide,
    Zondicon
  },
  data: function() {
    return {
      // path_url
      // path: "http://127.0.0.1:3000",
      path: "https://transkeep-engine.945.report",
      // loading
      loading: false,
      // show
      show_rice: false,
      show_category: false,
      show_subCategory: false,
      show_amout: false,
      show_submit: false,
      show_supplier: false,
      show_quanlity: false,
      show_note: false,
      picked: null,
      divisionData: [],
      division: null,

      divisionName: "",
      // camera
      files: [],
      selectedFile: [],
      tempFile: [],
      list_image: [],

      // category
      result_get_category: [],
      dropdown_category: "Category",
      value_cat_id: "",

      // supplier
      result_supplier: [],
      dropdown_supplier: "Supplier",
      value_supplier: "",

      // amount
      input_amount: "",
      input_quantity: "",

      //   note
      input_note: "",
      // submit

      // session
      session_username_id: "",

      session_division: [],
      //   loading percent
      loading_percent: 0,
      options: {
        text: {
          color: "#FFFFFF",
          shadowEnable: true,
          shadowColor: "#000000",
          fontSize: 14,
          fontFamily: "Helvetica",
          dynamicPosition: false,
          hideText: false
        },
        progress: {
          color: "#008894",
          backgroundColor: "#333333"
        },
        layout: {
          height: 35,
          width: 200,
          verticalTextAlign: 61,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 0,
          type: "line"
        }
      }
    };
  },
  watch: {
    picked: function(val) {
      axios
        .get(this.path + "/service/getDivisionNameById?id=" + val)
        .then(response => {
          this.divisionName = response.data[0].division;
          console.log(this.divisionName);
        });
    }
  },
  mounted() {
    if (!this.$session.get("session_username")) {
      this.$router.push({ name: "Login" });
    }
    console.log(
      "test => ",
      this.$session.get("session_username_division"),
      "type =>",
      typeof this.$session.get("session_username_division")
    );
    this.session_division = JSON.parse(
      this.$session.get("session_username_division")
    );
    this.session_username_id = this.$session.get("session_username_id");
    this.getDivisionData(this.session_division);
  },
  methods: {
    getDivisionData(data) {
      let dataObj = {
        division: data
      };
      axios
        .post(this.path + "/service/getDivisionById", dataObj)
        .then(response => {
          this.divisionData = response.data;
          console.log(response);
        });
    },

    getCategory() {
      axios.get(this.path + "/service/get-category").then(response => {
        this.result_get_category = response.data.items;
        this.show_category = true;
      });
    },
    selectedCategory(e) {
      this.show_rice = false;
      this.show_supplier = false;
      this.show_quanlity = false;
      this.show_amout = true;
      this.show_submit = true;
      this.show_note = true;
      this.input_note = "";

      this.value_cat_id = JSON.parse(e.target.value);
    },

    handleFilesUpload() {
      this.getCategory();
      this.files = [];
      this.tempFile = [];
      var uploadedFiles = []
      uploadedFiles = this.$refs.files.files;
      var that = this;
      if (uploadedFiles) {
        that.selectedFile = [];
        for (var i = 0; i < uploadedFiles.length; i++) {
          this.files.push(uploadedFiles[0]);
          this.tempFile.push(uploadedFiles[0]);
          var reader = new FileReader();
          reader.onload = function(e) {
            that.selectedFile.push(e.target.result);
            that.tempFile.push(e.target.result);
          };
          reader.readAsDataURL(this.files[0]);
        }
        // console.log(this.files)
        // console.log(this.tempFile);
      }
    },

    removeFile(index) {
      this.selectedFile.splice(index, 1);
      this.files.splice(index, 1);
      this.tempFile.splice(index, 1);
    },
    selectDivision() {
      this.division = this.picked;
    },

    submit() {
      if (this.dropdown_category == "Category") {
        alert("กรุณาเลือก Category");
      } else if (!this.input_amount) {
        alert("กรุณากรอก Amount");
      } else {
        this.loading = true;
        // upload to digitalocean
        let formData = new FormData();
        for (var i = 0; i < this.tempFile.length; i++) {
          let file = this.tempFile[i];
          formData.append("files", file);
        }

        axios
          .post(this.path + "/service/upload-image", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            },
            onUploadProgress: uploadEvent => {
              console.log(
                "upload : " +
                  Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                  "%"
              );
              this.loading_percent = Math.round(
                (uploadEvent.loaded / uploadEvent.total) * 100
              );
            }
          })
          .then(response => {
            for (var i = 0; i < response.data.length; i++) {
              var image = {
                img_url: response.data[i].location
              };
              this.list_image.push(image);
            }

            var temp_note;
            if (this.input_note) {
              temp_note = this.input_note;
            } else {
              temp_note = null;
            }

            var data = {
              cat_id: this.value_cat_id.cat_id,
              division_id: this.picked,
              amount: this.input_amount,
              user_id: this.session_username_id,
              object_img: this.list_image,
              note: temp_note
            };

            axios
              .post(this.path + "/service/insert-main-transaction", data, {
                headers: {
                  "Content-Type": "application/json"
                }
              })
              .then(response => {
                this.$session.set("session_transaction_id", response.data);
                this.$router.push({ name: "View" });
                this.$session.set("from_page", "create");
              });
          })
          .catch(error => {
            console.log(error);
          });
      }
    }
  }
};
</script>

<style lang="scss">
.page_home_2 {
  display: grid;

  .container {
    width: 95%;
    padding: 20px;
         .head-title{
   text-align: center;
      }

    .detail {
      text-align: center;
      // margin-top: 5px;
      background-color: #ffffff;
      margin: 5px;
      padding: 20px;
      -webkit-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      -moz-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
    }
    .section_camera {
      text-align: center;

      input {
        display: none;
      }

      label {
        color: #fff;
        padding: 10px 30px;
        // background: #ffffff;
        border: none;
        border-radius: 5px;
        -webkit-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
        -moz-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
        box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
        background-image: linear-gradient(
          to right top,
          #5433ff,
          #0068ff,
          #008aff,
          #00a6ff,
          #20bdff
        );
      }

      .preview {
        margin-top: 20px;
        .btnRemoveFile {
          color: #fff;
          font-weight: bold;
          padding: 5px 10px;
          // background: #ffffff;
          border: none;
          border-radius: 5px;
          -webkit-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
          -moz-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
          box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
          background-image: linear-gradient(
            to right top,
            #e53935,
            #e5433f,
            #e54c49,
            #e45552,
            #e35d5b
          );
        }
      }
    }

    .section_category {
      text-align: center;
      select {
        width: 70%;
        outline: none;
        padding: 10px 20px;
      }
    }

    .section_sub_category {
      text-align: center;
      select {
        width: 70%;
        outline: none;
        padding: 10px 20px;
      }
    }

    .section_amount {
      text-align: center;
      input {
        width: 70%;
        padding: 10px 20px;
        outline: none;
      }
    }

    .section_amount {
      text-align: center;
    }

    .section_submit {
      text-align: center;
      button {
        color: #fff;
        font-weight: bold;
        padding: 10px 30px;
        // background: #ffffff;
        border: none;
        border-radius: 5px;
        -webkit-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
        -moz-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
        box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
        background-image: linear-gradient(
          to right top,
          #56ab2f,
          #6bb83c,
          #80c549,
          #94d356,
          #a8e063
        );

        &:hover {
          color: #fff;
          background-color: rgb(0, 136, 148);
        }
      }
    }
    .section_Back {
      text-align: center;
      margin-top: 50px;
      button {
        color: #fff;
        font-weight: bold;
        padding: 10px 30px;
        // background: #ffffff;
        border: none;
        border-radius: 5px;
        -webkit-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
        -moz-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
        box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
        background-image: linear-gradient(
          to right top,
          #e53935,
          #e5433f,
          #e54c49,
          #e45552,
          #e35d5b
        );
      }
    }

    .section_division{
      text-align: center;
     button {
       width: 300px;
        margin-top: 30px;
        color: #fff;
        font-weight: bold;
        padding: 10px 30px;
        // background: #ffffff;
        border: none;
        border-radius: 5px;
        -webkit-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
        -moz-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
        box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
        background-image: linear-gradient(
          to right top,
          #059605,
          #059605,
          #059605,
          #059605,
          #059605
        );
      }

.sub-detail{
  display: block;
  background-color: #eeeeee;
  border-radius: 5px;
}
 .sub-box{
           text-align: left;
           display: inline-block;
           width: 50%;
           height: 45px;    
          padding-left: 10px;
          padding-top: 10px;
      }
      .sub-boxchk{
           text-align: right;
           display: inline-block;
           width: 50%;
           vertical-align: bottom;
          padding-right: 10px;
          height: auto; 
      }
          .box-btn{
           text-align: center;
           display: inline-block;
           width: 100%;
      }

    
    
    }

.wrapper {
  display: grid;
  grid-template-columns: 100px 100px 100px;
  grid-gap: 10px;
  background-color: #fff;
  color: #444;
  text-align: center;
}

.box {
  background-color: #444;
  color: #fff;
  border-radius: 5px;
  padding: 20px;
  font-size: 150%;
}



  }

  .progress_bar {
    position: absolute;
    top: 45%;
    left: 25%;
    transform: translate(-50%, -50%);
  }
}
</style>