<template>
  <div class="container_view_transaction">
    <div class="header_button" style="width: 10%">
      <div v-if="$session.get('from_page') == 'view_menu'">
        <router-link :to="{ name: 'ViewMenu' }" tag="p">
          <Zondicon icon="cheveron-left" style="width: 30px; margin: 10px 0 0 10px" />
        </router-link>
      </div>
    </div>
    <div class="detail">
      <div class="item">
        <div class="topic">เจ้าหน้าที</div>
        <div class="content">{{result_get_transaction.user_name}}</div>
      </div>
      <!--  -->
      <div class="hr"></div>
      <div class="hr"></div>
      <div class="item">
        <div class="topic">วันที่</div>
        <div
          class="content"
        >{{result_get_transaction.record_timestamp | moment("YYYY-MM-DD HH:mm") }}</div>
      </div>
      <!--  -->
      <div class="hr"></div>
      <div class="hr"></div>
      <!--  -->
      <div class="item">
        <div class="topic">ประเภท</div>
        <div class="content">{{result_get_transaction.category}}</div>
      </div>
      <!--  -->
      <div class="hr"></div>
      <div class="hr"></div>
      <!--  -->
      <div class="item">
        <div class="topic">หน่วยงาน</div>
        <div class="content">{{result_get_transaction.division}}</div>
      </div>
      <div class="hr"></div>
      <div class="hr"></div>
      <div class="item">
        <div class="topic">จำนวนเงิน</div>
        <div class="content">{{Number(result_get_transaction.amount).toLocaleString()}}</div>
      </div>
      <div class="hr"></div>
      <div class="hr"></div>
      <div class="item">
        <div class="topic">บันทึกเพิ่มเติม</div>
        <div class="content">{{ result_get_transaction.note }}</div>
      </div>
      <div class="hr"></div>
      <div class="hr"></div>
      <div class="item">
        <div class="topic">สถานะ</div>
        <div class="content">{{result_get_transaction.approve_status}}</div>
      </div>
      <div class="hr"></div>
      <div class="hr"></div>
      <div class="item">
        <div class="topic">เหตุผลการปฏิเสธ</div>
        <div
          class="content"
          v-if="result_get_transaction.reject_note != null"
        >{{result_get_transaction.reject_note}}</div>
        <div class="content" v-else>-</div>
      </div>
      <div class="hr"></div>
      <div class="hr"></div>
    </div>

    <div class="preview_view">
      <viewer v-bind:key="item.id" v-for="(item , index ) in result_get_image_transaction">
        <img
          :src="result_get_image_transaction[index].image_url"
          :key="result_get_image_transaction[index].id"
          style="height:160px;"
        />
      </viewer>
    </div>

    <div v-if="$session.get('from_page') == 'create'" class="section_button">
      <router-link :to="{ name: 'Home' }" tag="p">
        <button>OK</button>
      </router-link>
    </div>
  </div>
</template>

<script>
const axios = require("axios");
// import { Carousel, Slide } from "vue-carousel";
import Zondicon from "vue-zondicons";
export default {
  components: {
    // Carousel,
    // Slide,
    Zondicon
  },
  data: function() {
    return {
      // path_url
      // path: "http://127.0.0.1:3000",
      path: "https://transkeep-engine.945.report",
      result_get_transaction: [],
      result_get_image_transaction: []
    };
  },
  mounted() {
    if (!this.$session.get("session_username")) {
      this.$router.push({ name: "Login" });
    }
    this.getDataTransaction();
    this.getImageTransaction();
  },
  methods: {
    getDataTransaction() {
      var data = {
        transaction_id: this.$session.get("session_transaction_id")
      };

      axios
        .post(this.path + "/service/get-data-transaction-id", data)
        .then(response => {
          this.result_get_transaction = response.data.items[0];
        });
    },
    getImageTransaction() {
      var data = {
        transaction_id: this.$session.get("session_transaction_id")
      };

      axios
        .post(this.path + "/service/get-img-transaction-id", data)
        .then(response => {
          this.result_get_image_transaction = response.data.items;
        });
    }
  }
};
</script>

<style lang="scss">
.container_view_transaction {
  color: #555555;

  .header_button {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .detail {
    margin-top: 5px;
    background-color: #ffffff;
    padding: 20px;
    -webkit-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
    -moz-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
    box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);

    .hr {
      height: 0.5px;
      background-color: #d3d3d3;
    }
    .item {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin: 10px auto;
      .topic {
        // font-weight: bold;
      }
      .content {
        text-align: right;
      }
    }
  }

  .preview_view {
    background-color: #ffffff;
    margin: 50px auto;
    text-align: center;
    padding: 40px 0;
    -webkit-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
    -moz-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
    box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
  }
  .responsive {
    width: 100%;
    height: auto;
    padding-right: 30px;
    padding-left: 30px;
  }

  .section_button {
    text-align: center;
    margin: 50px auto;
    button {
      color: #fff;
      font-weight: bold;
      padding: 10px 30px;
      // background: #ffffff;
      border: none;
      border-radius: 5px;
      -webkit-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      -moz-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      background-image: linear-gradient(
        to right top,
        #56ab2f,
        #6bb83c,
        #80c549,
        #94d356,
        #a8e063
      );
    }
  }

  .section_Back {
    text-align: center;
    margin: 50px auto;
    button {
      color: #fff;
      font-weight: bold;
      padding: 10px 30px;
      // background: #ffffff;
      border: none;
      border-radius: 5px;
      -webkit-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      -moz-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      background-image: linear-gradient(
        to right top,
        #e53935,
        #e5433f,
        #e54c49,
        #e45552,
        #e35d5b
      );
    }
  }
}
</style>