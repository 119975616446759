<style>

input {
  display: block;
  width: 350px;
  margin: 20px auto;
  padding: 10px 45px;
  /* background: white url("assets/search-icon.svg") no-repeat 15px center; */
  background-size: 15px 15px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

</style>
<template>
  <div class="view_menu">
    <div v-if="show.division && !show.status" class="header_button" style="width: 10%">
      <router-link :to="{ name: 'Home' }" tag="p">
        <Zondicon icon="cheveron-left" style="width: 30px; margin: 10px 0 0 10px" />
      </router-link>
    </div>
    <div v-else-if="!show.division && show.status" class="header_button" style="width: 10%">
      <a @click="backStep('bDivsion')">
        <Zondicon icon="cheveron-left" style="width: 30px; margin: 10px 0 0 10px" />
      </a>
    </div>
    <div v-else-if="!show.division && !show.status" class="header_button" style="width: 10%">
      <a @click="backStep('bStatus')">
        <Zondicon icon="cheveron-left" style="width: 30px; margin: 10px 0 0 10px" />
      </a>
    </div>
    <div v-if="show.division && !show.status" class="section_division">
      <div class="detail">
        <div class="sub-detail">
          <div class="sub-box">
            <label>เลือกทั้งหมด</label>
          </div>
          <div class="sub-boxchk">
            <input type="checkbox" v-model="selectDivAll" style="width: 25px;height: 25px;" />
          </div>
        </div>
        <br />

        <div v-for="item in divisionData" v-bind:key="item.record_id">
          <div class="sub-detail">
            <div class="sub-box">
              <label :for="item.division">{{ item.division }} ({{ item.length }})</label>
            </div>
            <div class="sub-boxchk">
              <input
                type="checkbox"
                :value="item.record_id"
                v-model="checkDivision"
                style="width: 25px;height: 25px;"
              />
            </div>
          </div>
          <br />
        </div>
      </div>
      <div class="box-btn">
        <button @click="filter('division')">ถัดไป</button>
      </div>
      <br />
    </div>

    <div v-else-if="!show.division && show.status" class="section_division">
      <div class="detail">
        <div class="sub-detail">
          <div class="sub-box">
            <label>เลือกทั้งหมด</label>
          </div>
          <div class="sub-boxchk">
            <input type="checkbox" v-model="selectStatAll" style="width: 25px;height: 25px;" />
          </div>
        </div>
        <br />
        <div v-for="item in approveData" v-bind:key="item.id">
          <div class="sub-detail">
            <div class="sub-box">
              <label :for="item.name">{{ item.name }} ({{ item.length }})</label>
            </div>
            <div class="sub-boxchk">
              <input
                type="checkbox"
                :value="item.name"
                v-model="checkStatus"
                style="width: 25px;height: 25px;"
              />
            </div>
          </div>
          <br />
        </div>
      </div>
      <div class="box-btn">
        <button @click="filter('status')">ถัดไป</button>
      </div>
      <br />
    </div>

    <div v-else class="section_division">
      <h3 style=" text-align: center ;">รายการ</h3>
      <div class="content">
        <div
          v-for="(item , index) in result_get_data"
          v-bind:key="item.id"
          class="item"
          v-on:click="detail(item)"
        >
          <div class="left">
            <small>ประเภท : {{result_get_data[index].category}}</small>
            <br />
            <small>หน่วยงาน : {{result_get_data[index].division}}</small>
            <br />
            <small>บันทึกเพิ่มเติม : {{result_get_data[index].note}}</small>
            <br />
            <small>สถานะ : {{result_get_data[index].approve_status}}</small>
            <br />
            <small
              v-if="result_get_data[index].reject_note != null"
            >เหตุผลการปฏิเสธ : {{result_get_data[index].reject_note}}</small>
            <small v-else>เหตุผลการปฏิเสธ : -</small>
            <br />
            <small
              v-if="$session.get('session_username_id') == 1"
            >โดย : {{result_get_data[index].user_name}}</small>
          </div>
          <div class="right">
            <label
              style="font-size: 28px ; font-weight: bold ; "
            >{{result_get_data[index].amount.toLocaleString()}}</label>
            <br />
            <small>{{result_get_data[index].record_timestamp | moment("YYYY-MM-DD")}}</small>
          </div>
        </div>
      </div>
      <div class="footer">
        <pagination v-model="page" :records="total" :per-page="50" @paginate="myCallback"/>
    </div>
    </div>
  </div>
</template>


<script>
const axios = require("axios");
import Zondicon from "vue-zondicons";
import Pagination from 'vue-pagination-2';

const customStyles = {
    li: {
        display: 'inline-block',
        border: '2px dotted green'
    },
    a: {
        color: 'blue'
    }
};

export default {
  components: {
    Zondicon,
    Pagination
  },
  data: function() {
    return {
      // path_url
      // path: "http://localhost:3300",
      path: "https://transkeep-engine.945.report",
      result_get_data: [],
      divisionData: [],
      approveData: [],
      checkDivision: [],
      checkStatus: [],
      show: {
        division: false,
        status: false
      },
      allSelected: false,
      filterData: {
        division: [],
        status: []
      },
       pageOfItems: [],
       customStyles,
      page:1,
      total:0,
    };
  },
  mounted() {
    if (!this.$session.get("session_username")) {
      this.$router.push({ name: "Login" });
    }
    if (this.$session.get("session_username_role") == 0) {
      this.$router.push({ name: "Home" });
    }
    this.session_division = JSON.parse(
      this.$session.get("session_username_division")
    );
    
    if (typeof this.$session.get("filterData") !== "undefined") {
      this.show.division = false;
      this.show.status = false;
      let filter = this.$session.get("filterData");
      this.checkDivision = filter.division;
      this.checkStatus = filter.status;
      this.filterData.division = filter.division;
      this.filterData.status = filter.status;
      this.getDataViewMenu();
      this.$session.remove("filterData");
      
    } else {
      this.getDivisionData(this.session_division);
    }
  },
  computed: {
    selectDivAll: {
      get: function() {
        return this.divisionData
          ? this.checkDivision.length == this.divisionData.length
          : false;
      },
      set: function(value) {
        var selected = [];

        if (value) {
          this.divisionData.forEach(user => {
            selected.push(user.record_id);
          });
        }

        this.checkDivision = selected;
      }
    },
    selectStatAll: {
      get: function() {
        return this.approveData
          ? this.checkStatus.length == this.approveData.length
          : false;
      },
      set: function(value) {
        var selected = [];

        if (value) {
          this.approveData.forEach(user => {
            selected.push(user.name);
          });
        }

        this.checkStatus = selected;
      }
    }
  },
  methods: {
    backStep(action) {
      if (action == "bDivsion") {
        this.checkStatus = [];
        this.filterData.status = [];
        this.session_division = JSON.parse(
          this.$session.get("session_username_division")
        );
        this.getDivisionData(this.session_division);
        this.show.division = true;
        this.show.status = false;
      } else if (action == "bStatus") {
        this.getStatusByDivisionId();
        this.show.division = false;
        this.show.status = true;
      }
    },
    filter(type) {
      if (type == "division") {
        if (this.checkDivision.length == 0) {
          alert("กรุณาเลือกอย่างน้อย 1 ข้อ");
        } else {
          this.checkStatus = [];
          this.filterData.status = [];
          this.filterData.division = this.checkDivision;
          this.show.division = false;
          this.show.status = true;
          this.getStatusByDivisionId();
        }
      } else if (type == "status") {
        if (this.checkStatus.length == 0) {
          alert("กรุณาเลือกอย่างน้อย 1 ข้อ");
        } else {
          this.filterData.status = this.checkStatus;
          this.getDataViewMenu();
          this.show.division = false;
          this.show.status = false;
        }
      }
    },
    getDivisionData(data) {
      let dataObj = {
        division: data
      };
      axios
        .post(this.path + "/service/getDivisionById", dataObj)
        .then(response => {
          this.divisionData = response.data;
          this.show.division = true;
        });
    },
    getStatusByDivisionId() {
      let dataObj = {
        division: this.checkDivision
      };
      axios
        .post(this.path + "/service/getStatusByDivisionId", dataObj)
        .then(response => {
          if (response.data.length == 0) {
            alert("ไม่พบข้อมูล");
            location.reload();
          } else {
            this.approveData = response.data;
          }
        });
    },
    myCallback(page){
      this.filterData.page = page;
      this.getDataViewMenu();
    },
    getDataViewMenu() {
      if (this.$session.get("session_username_role") == 1) {
        axios
          .post(this.path + "/service/getFilterDataAdmin", this.filterData)
          .then(response => {
            this.result_get_data = response.data.items;
            this.total = response.data.total;
          });
      }
    },
    detail(e) {
      this.$session.set("session_transaction_id", e.transaction_id);
      this.$session.set("from_page", "check_list");
      this.$session.set("filterData", this.filterData);
      this.$router.push({ name: "Edit" });
    },
    onChangePage(pageOfItems) {
        this.pageOfItems = pageOfItems;
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
  }
};
</script>

<style lang="scss">
.view_menu {
  .content {
    display: grid;
    grid-template-columns: 1fr;

    .item:nth-child(even) {
      color: #363636;
      display: grid;
      grid-template-columns: 1fr 1fr;
      // background-image: linear-gradient(to right top, #0f2027, #12252c, #152931, #192e37, #1c333c, #1e3741, #213c46, #23404b, #254551, #274957, #2a4e5e, #2c5364);
      // background-image: linear-gradient(to right top, #8e9eab, #a4b3bc, #bcc8ce, #d4dde0, #eef2f3);
      background-image: linear-gradient(
        to right top,
        #ece9e6,
        #f2eeed,
        #f6f4f4,
        #faf9fa,
        #ffffff
      );
      margin: 10px;
      padding: 10px 15px;
      border: none;
      border-radius: 10px;
      -webkit-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      -moz-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      .left {
      }

      .right {
        text-align: right;
        // font-weight: bold;
      }
    }
    .item:nth-child(odd) {
      color: #fff;
      display: grid;
      grid-template-columns: 1fr 1fr;
      // background-image: linear-gradient(to right top, #0f0c29, #131433, #19193e, #1f1e49, #262454, #242b5b, #223162, #203868, #184069, #184768, #204d66, #2c5364);
      // background-image: linear-gradient(to right top, #1d976c, #40af7f, #5cc792, #78e0a5, #93f9b9);
      // background-image: linear-gradient(to right top, #1d976c, #39a275, #4dad7d, #60b987, #72c490);
      background-image: linear-gradient(
        to right top,
        #19815d,
        #298e64,
        #389c6b,
        #46a972,
        #55b779
      );
      margin: 10px;
      padding: 10px 15px;
      border: none;
      border-radius: 10px;
      -webkit-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      -moz-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      .left {
      }

      .right {
        text-align: right;
        // font-weight: bold;
        // text-shadow: 1px 1px .5px #000;
      }
    }
  }

  .section_Back {
    text-align: center;
    margin: 50px auto;

    button {
      color: #fff;
      font-weight: bold;
      padding: 10px 30px;
      // background: #ffffff;
      border: none;
      border-radius: 5px;
      -webkit-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      -moz-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      background-image: linear-gradient(
        to right top,
        #e53935,
        #e5433f,
        #e54c49,
        #e45552,
        #e35d5b
      );
    }
  }

  .section_division {
    button {
      width: 300px;
      margin-top: 30px;
      color: #fff;
      font-weight: bold;
      padding: 10px 30px;
      // background: #ffffff;
      border: none;
      border-radius: 5px;
      -webkit-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      -moz-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      background-image: linear-gradient(
        to right top,
        #059605,
        #059605,
        #059605,
        #059605,
        #059605
      );
    }

    .detail {
      margin-top: 5px;
      background-color: #ffffff;
      margin: 20px;
      padding: 20px;
      -webkit-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      -moz-box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
      box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.33);
    }

    .sub-detail {
      display: block;
      background-color: #eeeeee;
      border-radius: 5px;
    }
    .sub-box {
      text-align: left;
      display: inline-block;
      width: 50%;
      height: 45px;
      padding-left: 10px;
      padding-top: 10px;
    }
    .sub-boxchk {
      text-align: right;
      display: inline-block;
      width: 50%;
      vertical-align: bottom;
      padding-right: 10px;
      height: auto;
    }
    .box-btn {
      text-align: center;
      display: inline-block;
      width: 100%;
    }
  }
}
</style>